import { TQuestionOption } from "../../services/types/utils.types";
import { useDrag, useDrop } from "react-dnd";
import { ITEM_TYPE } from "../../utils/constants";
import AddOrEditOptionForm from "../forms/addOrEditOption/addOrEditOptionForm";

interface DraggableOptionProps {
  option: TQuestionOption;
  groupId: number | null;
  moveOption: (
    draggedOption: TQuestionOption,
    targetOption: TQuestionOption
  ) => void;
  updateGroup: (
    draggedOption: TQuestionOption,
    newGroupId: number | null
  ) => void;
  updateForm: (updatedOption: TQuestionOption, id: number) => void;
}

function DraggableOption({
  option,
  groupId,
  moveOption,
  updateGroup,
  updateForm,
}: DraggableOptionProps) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ITEM_TYPE.OPTION,
    item: { option, groupId },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop({
    accept: ITEM_TYPE.OPTION,
    hover: (draggedItem: {
      option: TQuestionOption;
      groupId: number | null;
    }) => {
      if (
        draggedItem.option.id !== option.id &&
        groupId === draggedItem.groupId
      ) {
        moveOption(draggedItem.option, option);
        console.log(55);
      } else if (groupId !== draggedItem.groupId) {
        updateGroup(draggedItem.option, groupId);
      }
    },
  });

  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <AddOrEditOptionForm
        form={option}
        onFormChange={(updatedOption) => updateForm(updatedOption, option.id)}
      />
    </div>
  );
}

export default DraggableOption;
