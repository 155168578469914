import styles from "./addNewFeature.module.css";
import { addNewFeatureInitialState } from "../../utils/constants";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { useNavigate, useParams } from "react-router-dom";
import AddOrEditFeatureForm from "../../components/forms/addOrEditFeature/addOrEditFeature";
import { useAppSelector } from "../../services/hooks";
import { featuresSelector } from "../../services/selectors/utils.selectors";

function EditFeaturePage() {
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>() || "";
  const features = useAppSelector(featuresSelector);
  const feature = features.find((f) => f.Id === Number(id));

  const [form, setForm] = useState(addNewFeatureInitialState);
  useEffect(() => {
    if (feature?.Id) {
      setForm((prev) => ({
        ...prev,
        Name: feature.Desc,
        Symbol: feature.Symbol,
        number: feature.Number,
      }));
    }
  }, []);

  // const [editFeature, { isLoading }] = useEditFeatureMutation(); to uncomment when endpoint exists

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    // editFeature({ row: form, id: Number(id) }).then((response) =>
    //   console.log(response)
    // );
    navigate("/protocols/features");
  };
  return (
    <section className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        position={"center"}
        onClick={() => navigate(-1)}
      />
      <h1 className={styles.section__title}>הוספת הפיצ'ר חדש</h1>
      <AddOrEditFeatureForm
        form={form}
        onSubmit={onSubmit}
        handleChange={handleChange}
        isLoading={false}
      />
    </section>
  );
}

export default EditFeaturePage;
