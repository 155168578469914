import styles from "./rrmModel.module.css";
import questionnaireStyles from "../../pages/questionnaires/questionnaires.module.css";
import { noDataLabel } from "../../utils/constants";
import { useAppSelector } from "../../services/hooks";
import { companyGroupsRRMSelector } from "../../services/selectors/utils.selectors";
import React, { Dispatch, SetStateAction } from "react";
import { ICompanyGroupsRRM } from "../../services/types/utils.types";

function RrmModel({
  setRrmGroupToEdit,
  setShowGroupsRRMForm,
}: {
  setRrmGroupToEdit: Dispatch<SetStateAction<null | ICompanyGroupsRRM>>;
  setShowGroupsRRMForm: Dispatch<SetStateAction<boolean>>;
}) {
  const rrmGroups = useAppSelector(companyGroupsRRMSelector);

  return (
    <article className={styles.card}>
      <ul className={styles.card__list}>
        <li className={`${styles.card__li} ${styles.card__li_title}`}>
          <span className={styles.card__text}>תמרור</span>
          <span className={styles.card__text}>תיאור</span>
          <span className={styles.card__text}>סטטוס</span>
          <span className={styles.card__text}>אנליזה</span>
          <span className={styles.card__text}>המלצות</span>
          <span className={styles.card__text}>דגשים</span>
          <span className={styles.card__text}>במהלך השיחה</span>
          <span className={styles.card__text}>התחלה</span>
          <span className={styles.card__text}>סוף</span>
          <span className={styles.card__text}>עריכה</span>
        </li>
        {rrmGroups.map((item) => (
          <li className={`${styles.card__li}`} key={item.id}>
            <div
              className={styles.card__cell}
              style={{ backgroundColor: item.color || "#fff" }}
            >
              {item.title || noDataLabel}
            </div>
            <span className={styles.card__text}>{item.desc || "-"}</span>
            <span className={styles.card__text}>
              {item.statusId === 0 ? "לא פעיל" : "פעיל"}
            </span>
            <span className={styles.card__text}>{item.anliza || "-"}</span>
            <span className={styles.card__text}>
              {item.recommendations || "-"}
            </span>
            <span className={styles.card__text}>{item.highlights || "-"}</span>
            <span className={styles.card__text}>
              {item.conversation || "-"}
            </span>
            <span className={styles.card__text}>{item.start || "-"}</span>
            <span className={styles.card__text}>{item.end || "-"}</span>
            <button
              type={"button"}
              className={`${questionnaireStyles.btn} ${questionnaireStyles.btn_type_edit}`}
              onClick={() => {
                setRrmGroupToEdit(item);
                setShowGroupsRRMForm(true);
              }}
            />{" "}
          </li>
        ))}
      </ul>
    </article>
  );
}

export default RrmModel;
