import styles from "./addOrEditQuestionnaire.module.css";
import Select, { ActionMeta, SingleValue } from "react-select";
import {
  addOrEditQuestionnaireInitialState,
  statusOptions,
} from "../../../utils/constants";
import { TLabeledItem, TQuestionnaireTypeOption } from "../../../types/types";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { IQuestionnaireData } from "../../../services/types/utils.types";
import { useAddOrEditQuestionnaireMutation } from "../../../services/api/utils.api";
import { useAppSelector } from "../../../services/hooks";
import { adminSelector } from "../../../services/selectors/user.selectors";
import Modal from "../../modals/modal/Modal";
import GeneralSuccessPopup from "../../modals/generalSuccessPopup/generalSuccessPopup";
import ErrorPopup from "../../modals/errorPopup/errorPopup";
import QFactorsPopup from "../../modals/qFactors/qFactorsPopup";
import { useNavigate } from "react-router-dom";

interface IAddOrEditQuestionnaire {
  questionnaire: IQuestionnaireData | undefined;
}
function AddOrEditQuestionnaireForm({
  questionnaire,
}: IAddOrEditQuestionnaire) {
  const navigate = useNavigate();
  const admin = useAppSelector(adminSelector);

  const [form, setForm] = useState(addOrEditQuestionnaireInitialState);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [openQFactorsModal, setOpenQFactorsModal] = useState(false);
  const [touched, setTouched] = useState(false);

  const [editQuestionnaire, { isLoading }] =
    useAddOrEditQuestionnaireMutation();

  // Update form state with values from questionnaire
  useEffect(() => {
    setForm((prevState) => ({
      ...prevState,
      ...questionnaire, // This will update all the corresponding fields
    }));
  }, [questionnaire]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTouched(true);
    const field = e.target.id;
    setForm((prev) => ({ ...prev, [field]: e.target.value.trim() }));
  };
  const onSelectChange = (
    value: SingleValue<TLabeledItem | TQuestionnaireTypeOption>,
    { name }: ActionMeta<TLabeledItem>
  ) => {
    setTouched(true);
    name && value && setForm((prev) => ({ ...prev, [name]: value.value }));
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    editQuestionnaire({
      companyId: admin.companyId,
      body: { ...form, companyId: admin.companyId },
    })
      .then((res) => {
        if (res && "data" in res) {
          // @ts-ignore
          if (res.data?.success === "false") {
            console.log(res.data);
          }
          setOpenModalSuccess(true);
          setTouched(false);
        }
      })
      .catch((err) => {
        setOpenModalError(true);
        console.log(err);
      });
  };

  return (
    <form className={styles.form}>
      <ul className={styles.form__list}>
        <li>
          <label htmlFor="desc" className={styles.form__label}>
            שם שאלון
          </label>
          <input
            type="text"
            className={`${styles.form__input}`}
            name="desc"
            id="desc"
            value={form.desc}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="symbol" className={styles.form__label}>
            סימן
          </label>
          <input
            type="text"
            className={`${styles.form__input}`}
            name="symbol"
            id="symbol"
            value={form.symbol}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="factorQuestionnaire" className={styles.form__label}>
            משוקלת RRM
            <p
              className={styles.form__link}
              onClick={() => {
                setOpenQFactorsModal(true);
              }}
            >
              משקולות שאלונים
            </p>
          </label>
          <input
            className={`${styles.form__input}`}
            name="factorQuestionnaire"
            id="factorQuestionnaire"
            type={"number"}
            value={form.factorQuestionnaire}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="statusId" className={styles.form__label}>
            סטטוס
          </label>
          <Select
            name={"statusId"}
            options={statusOptions}
            value={
              statusOptions.find(
                (o) => o.value === form?.statusId?.toString()
              ) || undefined
            }
            placeholder={"אנא בחרסוג סטטוס"}
            className={styles.select}
            onChange={onSelectChange}
          />
        </li>
      </ul>
      <button
        type={"submit"}
        className={`button ${styles.form__btn} ${
          (isLoading || !touched) && styles.form__btn_disabled
        } `}
        disabled={isLoading || !touched}
        onClick={onSubmit}
      >
        {isLoading ? "שליחה" : questionnaire ? "לערוך שאלון" : "ליצור שאלון"}
      </button>
      {openQFactorsModal && (
        <Modal onClose={() => setOpenQFactorsModal(false)}>
          <QFactorsPopup onClose={() => setOpenQFactorsModal(false)} />
        </Modal>
      )}
      {openModalSuccess && (
        <Modal
          onClose={() => {
            setOpenModalSuccess(false);
            !questionnaire && navigate(-1);
          }}
        >
          <GeneralSuccessPopup
            onClose={() => {
              setOpenModalSuccess(false);
              !questionnaire && navigate(-1);
            }}
          />
        </Modal>
      )}
      {openModalError && (
        <Modal onClose={() => setOpenModalError(false)}>
          <ErrorPopup onClose={() => setOpenModalError(false)} />
        </Modal>
      )}
    </form>
  );
}

export default AddOrEditQuestionnaireForm;
