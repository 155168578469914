import styles from "./addOrEditQuestion.module.css";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AddOrEditQuestionForm from "../../components/forms/addOrEditQuestion/addOrEditQuestionForm";
import { useGetQuestionsMutation } from "../../services/api/utils.api";
import { useAppSelector } from "../../services/hooks";
import { questionsSelector } from "../../services/selectors/utils.selectors";
import { IQuestion } from "../../services/types/utils.types";
import { adminSelector } from "../../services/selectors/user.selectors";

function AddOrEditQuestion() {
  const { id, qid } = useParams();
  const navigate = useNavigate();

  const questions = useAppSelector(questionsSelector);
  const admin = useAppSelector(adminSelector);

  const [question, setQuestion] = useState<IQuestion | undefined>(undefined);

  const [getQuestions] = useGetQuestionsMutation();

  useEffect(() => {
    qid && getQuestions({ questionnaireId: qid });
  }, [getQuestions, id, qid, admin.companyId]);

  useEffect(() => {
    setQuestion(questions.find((q) => q.id.toString() === id));
  }, [questions, setQuestion, id]);

  return (
    <section className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        position={"center"}
        onClick={() => navigate(-1)}
      />
      <h1 className={styles.section__title}>
        {id ? "עריכה של שאלה קיימת" : "הוספת שאלה חדשה"}
      </h1>
      <AddOrEditQuestionForm question={question} />
    </section>
  );
}

export default AddOrEditQuestion;
