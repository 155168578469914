import {
  IQuestion,
  IQuestionnaireData,
  TQuestionOption,
  TUtilsState,
  TGroup,
  ICompanyGroupsRRMHeaders,
  ICompanyGroupsRRM,
} from "../types/utils.types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { utilsApi } from "../api/utils.api";
import { sortOptionsByGroup } from "../../utils/utils";

const initialState: TUtilsState = {
  questionnaires: [],
  questions: [],
  questionsSortedByQuestionnaire: {},
  questionOptions: [],
  questionOptionsGroupped: [],
  nextQuestionOptions: [],
  groups: [],
  companyGroupsRRMHeaders: [],
  companyGroupsRRM: [],
  features: [],
  exercises: [],
  libraryCategories: [],
  error: "",
};

export const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        utilsApi.endpoints.getQuestionnaires.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<IQuestionnaireData[]>) => {
          return {
            ...state,
            questionnaires: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.addOrEditQuestionnaire.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<IQuestionnaireData[]>) => {
          if (!Array.isArray(action.payload)) {
            return state; // Early return, no state update
          }
          return { ...state, questionnaires: action.payload };
        }
      )
      .addMatcher(
        utilsApi.endpoints.deleteQuestionnaire.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<IQuestionnaireData[]>) => {
          return { ...state, questionnaires: action.payload };
        }
      )
      // .addMatcher(
      //   utilsApi.endpoints.searchQuestionnaire.matchFulfilled,
      //   (state: TUtilsState, action: PayloadAction<IQuestionnaireData[]>) => {
      //     return { ...state, questionnaires: action.payload };
      //   }
      // )
      // .addMatcher(
      //   utilsApi.endpoints.searchQuestions.matchFulfilled,
      //   (state: TUtilsState, action: PayloadAction<IQuestion[]>) => {
      //     return {
      //       ...state,
      //       questions: action.payload.sort((a, b) => a.seq - b.seq),
      //     };
      //   }
      // )
      .addMatcher(
        utilsApi.endpoints.getQuestions.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<IQuestion[]>) => {
          return {
            ...state,
            questions: action.payload.sort((a, b) => a.seq - b.seq),
            nextQuestionOptions: action.payload.map((q) => ({
              value: q.id.toString(),
              label: q.desc,
              id: q.id,
            })),
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.deleteQuestions.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<IQuestion[]>) => {
          return {
            ...state,
            questions: action.payload.sort((a, b) => a.seq - b.seq),
            nextQuestionOptions: action.payload.map((q) => ({
              value: q.id.toString(),
              label: q.desc,
              id: q.id,
            })),
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getQuestionsOptions.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TQuestionOption[]>) => {
          return {
            ...state,
            questionOptions: action.payload,
            questionOptionsGroupped: sortOptionsByGroup(action.payload),
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.addOrEditQuestionsOptions.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TQuestionOption[]>) => {
          return {
            ...state,
            questionOptions: action.payload,
            questionOptionsGroupped: sortOptionsByGroup(action.payload),
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getQuestionsGroups.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TGroup[]>) => {
          console.log(action.payload);
          return {
            ...state,
            groups: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.addOrEditQuestionsGroups.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TGroup[]>) => {
          return {
            ...state,
            groups: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getCompanyGroupsRRMHeaders.matchFulfilled,
        (
          state: TUtilsState,
          action: PayloadAction<ICompanyGroupsRRMHeaders[]>
        ) => {
          console.log(action.payload);
          return {
            ...state,
            companyGroupsRRMHeaders: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.addOrEditCompanyGroupsRRMHeaders.matchFulfilled,
        (
          state: TUtilsState,
          action: PayloadAction<ICompanyGroupsRRMHeaders[]>
        ) => {
          return {
            ...state,
            companyGroupsRRMHeaders: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getCompanyGroupsRRM.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<ICompanyGroupsRRM[]>) => {
          console.log(action.payload);
          return {
            ...state,
            companyGroupsRRM: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.addOrEditCompanyGroupsRRM.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<ICompanyGroupsRRM[]>) => {
          return {
            ...state,
            companyGroupsRRM: action.payload,
          };
        }
      );
  },
});

export const { actions, reducer } = utilsSlice;
export default utilsSlice.reducer;
