import { IQuestion } from "../../services/types/utils.types";
import { useNavigate } from "react-router-dom";
import styles from "../../pages/questions/questions.module.css";
import React from "react";

const QuestionsActionButtons = ({
  question,
  onDelete,
}: {
  question: IQuestion;
  onDelete: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <div className={styles.listCell}>
      <button
        type="button"
        className={`${styles.btn} ${styles.btn_type_edit}`}
        onClick={() => navigate(`questions/edit/${question.id}`)}
      />
      <button
        type="button"
        className={`${styles.btn} ${styles.btn_type_options}`}
        onClick={() => navigate(`options/${question.id}`)}
      />
      <button
        type="button"
        className={`${styles.btn} ${styles.btn_type_delete}`}
        onClick={onDelete}
      />
    </div>
  );
};

export default QuestionsActionButtons;
