import styles from "./questionnaires.module.css";
import { ChangeEvent, useEffect, useState } from "react";
import {
  useDeleteQuestionnaireMutation,
  useGetQuestionnairesMutation,
} from "../../services/api/utils.api";
import { useAppSelector } from "../../services/hooks";
import { questionnairesSelector } from "../../services/selectors/utils.selectors";
import Loader from "../../components/loader/loader";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/modals/modal/Modal";
import DeleteConfirmation from "../../components/modals/deleteConfirmation/deleteConfirmation";
import useMediaQuery from "../../hooks/useMediaQuery";
import { adminSelector } from "../../services/selectors/user.selectors";
import { IQuestionnaireData } from "../../services/types/utils.types";
import { filterObjectsByString } from "../../utils/search";

function QuestionnairesPage() {
  const navigate = useNavigate();
  const mobile = useMediaQuery("(max-width: 800px)");
  const questionnairesServer = useAppSelector(questionnairesSelector);
  const admin = useAppSelector(adminSelector);

  const [inputValue, setInputValue] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);
  const [questionnaireToDeleteId, setQuestionnaireToDeleteId] = useState("");
  const [questionnaires, setQuestionnaires] = useState<IQuestionnaireData[]>(
    []
  );
  const [toggle, setToggle] = useState({});

  const [getQuestionnaires, { isLoading }] = useGetQuestionnairesMutation();
  const [deleteQuestionnaire] = useDeleteQuestionnaireMutation();

  useEffect(() => {
    getQuestionnaires({ companyId: admin.companyId });
  }, [getQuestionnaires, admin.companyId]);
  useEffect(() => {
    setQuestionnaires(questionnairesServer);
  }, [setQuestionnaires, questionnairesServer]);
  //add filter logic
  useEffect(() => {
    setQuestionnaires(filterObjectsByString(inputValue, questionnairesServer));
  }, [setQuestionnaires, questionnairesServer, inputValue]);

  const toggleAnswer = (id: number) => {
    setToggle({
      ...toggle,
      [id]: !toggle[id as keyof typeof setToggle],
    });
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <section className={styles.questionnaires}>
      <div className={styles.header}>
        <button
          type={"button"}
          className={styles.addBtn}
          onClick={() => navigate("edit")}
        >
          {!mobile && "הוסף שאלון חדש"}
          <span className={styles.plus}>+</span>
        </button>
        <input
          type={"text"}
          placeholder={` סנן...  `}
          className={styles.searchInput}
          onChange={onInputChange}
          value={inputValue}
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : questionnaires.length > 0 ? (
        mobile ? (
          <ul className={styles.list}>
            {questionnaires.map((q, index) => (
              <li
                key={q.id}
                className={`listRow_mobile ${
                  toggle[index as keyof typeof setToggle] &&
                  "listRow_mobile_long"
                }`}
                onClick={() => toggleAnswer(index)}
              >
                <p className={styles.listCell}>
                  מספר:<span className={styles.listValue}>{q.id}</span>
                </p>
                <p className={styles.listCell}>
                  שם:<span className={styles.listValue}>{q.desc}</span>
                </p>
                <p className={styles.listCell}>
                  סימן:<span className={styles.listValue}>{q.symbol}</span>
                </p>
                <p className={styles.listCell}>משקולת RRM:</p>
                <p className={`${styles.listCell} ${styles.listValue}`}>
                  {q.factorQuestionnaire}
                </p>
                <p className={styles.listCell}>
                  סטטוס:
                  <span className={styles.listValue}>
                    {q.statusId === 1 ? "פעיל" : "לא פעיל"}
                  </span>
                </p>
                <p className={styles.listCell}>אפשרויות:</p>
                <div className={styles.listCell}>
                  <button
                    type={"button"}
                    className={`${styles.btn} ${styles.btn_type_edit}`}
                    onClick={() =>
                      navigate(`/protocols/questionnaires/edit/${q.id}`)
                    }
                  />
                  <button
                    type={"button"}
                    className={`${styles.btn} ${styles.btn_type_rrm}`}
                    onClick={() => {
                      navigate(`edit/${q.id}/rrm`);
                    }}
                  />
                  <button
                    type={"button"}
                    className={`${styles.btn} ${styles.btn_type_delete}`}
                    onClick={() => {
                      setQuestionnaireToDeleteId(q.id.toString());
                      setOpenModal(true);
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <ul className={styles.list}>
            <li className={`${styles.listRow} ${styles.listRow_header}`}>
              <p className={styles.listCell}>מספר:</p>
              <p className={styles.listCell}>שם:</p>
              <p className={styles.listCell}>סימן:</p>
              <p className={styles.listCell}>משקולת RRM:</p>
              <p className={styles.listCell}>סטטוס:</p>
              <p className={styles.listCell}>אפשרויות:</p>
            </li>
            {questionnaires.map((q, index) => (
              <li
                key={q.id}
                className={`${styles.listRow} ${
                  index % 2 === 0 && styles.listRow_colored
                }`}
              >
                <p className={styles.listCell}>{q.id}</p>
                <p className={styles.listCell}>{q.desc}</p>
                <p className={styles.listCell}>{q.symbol}</p>
                <p className={styles.listCell}>{q.factorQuestionnaire}</p>
                <p className={styles.listCell}>
                  {q.statusId === 1 ? "פעיל" : "לא פעיל"}
                </p>
                <div className={styles.listCell}>
                  <button
                    type={"button"}
                    className={`${styles.btn} ${styles.btn_type_edit}`}
                    onClick={() =>
                      navigate(`/protocols/questionnaires/edit/${q.id}`)
                    }
                  />
                  <button
                    type={"button"}
                    className={`${styles.btn} ${styles.btn_type_rrm}`}
                    onClick={() => {
                      navigate(`edit/${q.id}/rrm`);
                    }}
                  />
                  <button
                    type={"button"}
                    className={`${styles.btn} ${styles.btn_type_delete}`}
                    onClick={() => {
                      setQuestionnaireToDeleteId(q.id.toString());
                      setOpenModal(true);
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>
        )
      ) : (
        <p className={styles.text}>לא נמצאו תוצאות</p>
      )}
      {openModal && (
        <Modal onClose={() => setOpenModal(false)}>
          <DeleteConfirmation
            onClose={() => setOpenModal(false)}
            onDelete={() => {
              console.log(questionnaireToDeleteId);
              deleteQuestionnaire({
                companyId: admin.companyId,
                id: Number(questionnaireToDeleteId),
              });
            }}
            text={`האם את/ה בטוח/ה שברצונך למחוק את השאלון מספר ${questionnaireToDeleteId}?`}
          />
        </Modal>
      )}
    </section>
  );
}

export default QuestionnairesPage;
