import styles from "./addOrEditOptions.module.css";
import stylesQuestionnaire from "../questionnaires/questionnaires.module.css";
import formsStyles from "../../components/forms/forms.module.css";
import DraggableGroup from "../../components/draggableGroup/draggableGroup";
import DraggableOption from "../../components/draggableOption/draggableOption";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  useAddOrEditQuestionsGroupsMutation,
  useAddOrEditQuestionsOptionsMutation,
  useGetQuestionsGroupsMutation,
  useGetQuestionsMutation,
  useGetQuestionsOptionsMutation,
} from "../../services/api/utils.api";
import { useAppSelector } from "../../services/hooks";
import {
  groupsSelector,
  nextQuestionOptionsSelector,
  questionOptionsSelector,
} from "../../services/selectors/utils.selectors";
import { TGroup, TQuestionOption } from "../../services/types/utils.types";
import Loader from "../../components/loader/loader";
import Modal from "../../components/modals/modal/Modal";
import ErrorPopup from "../../components/modals/errorPopup/errorPopup";
import GeneralSuccessPopup from "../../components/modals/generalSuccessPopup/generalSuccessPopup";
import { INumberOption } from "../../types/types";
import AddNewOption from "../../components/addNewOption/addNewOption";
import AddOrEditGroupForm from "../../components/forms/addOrEditGroup/addOrEditGroupForm";
import Select from "react-select";

function AddOrEditOptions() {
  const { qid, id } = useParams();
  const navigate = useNavigate();

  const options = useAppSelector(questionOptionsSelector);
  const nextQuestionOptions = useAppSelector(nextQuestionOptionsSelector);
  const groups = useAppSelector(groupsSelector);

  const [form, setForm] = useState<TQuestionOption[]>([]);
  const [applyToAllQuestions, setApplyToAllQuestions] = useState(false);
  const [groupsState, setGroupsState] = useState<TGroup[]>([]);
  const [groupsSelectOptions, setGroupsSelectOptions] = useState<
    INumberOption[]
  >([]);
  const [touched, setTouched] = useState(false);
  const [showAddNewOptionForm, setShowAddNewOptionForm] = useState(false);
  const [showAddNewGroupForm, setShowAddNewGroupForm] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);

  const [getOptions, { isLoading }] = useGetQuestionsOptionsMutation();
  const [getGroups] = useGetQuestionsGroupsMutation();
  const [getQuestions] = useGetQuestionsMutation();
  const [addOrEditOptions] = useAddOrEditQuestionsOptionsMutation();
  const [addOrEditOptionsGroups] = useAddOrEditQuestionsGroupsMutation();

  // Fetch options and groups by id on component load
  useEffect(() => {
    id && getOptions({ questionsId: id });
    id && getGroups({ questionsId: id });
  }, [getOptions, getGroups, id]);

  useEffect(() => {
    if (options) {
      setForm(options);
    }
  }, [options]);

  useEffect(() => {
    qid && getQuestions({ questionnaireId: qid });
  }, [getQuestions, qid]);

  useEffect(() => {
    const sortedGroups = [...groups].sort(
      (a, b) => (a.seq ?? groups.length) - (b.seq ?? groups.length)
    );
    setGroupsState(sortedGroups);

    const optionsForGroupSelect = groups.map((g) => ({
      ...g,
      label: g.name,
      value: g.id,
    }));
    setGroupsSelectOptions([
      ...optionsForGroupSelect,
      { value: 0, label: "ללא קבוצה" },
    ]);
  }, [groups]);

  const updateForm = (updatedOption: TQuestionOption, id: number) => {
    setTouched(true);
    setForm((prev) => {
      return prev.map((f) => (f.id === id ? updatedOption : f));
    });
  };

  const onSubmit = async () => {
    setTouched(false);

    if (!id) return;

    try {
      const [optionsRes, groupsRes] = await Promise.all([
        addOrEditOptions({ questionsId: id, options: form }),
        addOrEditOptionsGroups({ questionsId: id, groups: groupsState }),
      ]);

      if ("data" in optionsRes && "data" in groupsRes) {
        setOpenModalSuccess(true);
      }
    } catch (err) {
      setOpenErrorModal(true);
      console.log(err);
    }
  };

  const moveGroup = (draggedGroup: TGroup, targetGroup: TGroup) => {
    setTouched(true);

    setGroupsState((prevGroups) => {
      const updatedGroups = prevGroups
        .filter((g) => g.id !== draggedGroup.id)
        .sort(
          (a, b) =>
            (a.seq ?? Number.MAX_SAFE_INTEGER) -
            (b.seq ?? Number.MAX_SAFE_INTEGER)
        );

      const targetIndex = updatedGroups.findIndex(
        (g) => g.id === targetGroup.id
      );
      const insertIndex =
        draggedGroup.seq === null ||
        draggedGroup.seq > (targetGroup.seq ?? Number.MAX_SAFE_INTEGER)
          ? targetIndex // Insert before target
          : targetIndex + 1; // Insert after target

      // Insert dragged group at the determined index
      updatedGroups.splice(insertIndex, 0, draggedGroup);

      // Update seq for all groups, ensuring the seq starts from 1
      return updatedGroups.map((g, index) => ({ ...g, seq: index + 1 }));
    });
  };

  const moveOption = (
    draggedOption: TQuestionOption,
    targetOption: TQuestionOption
  ) => {
    setTouched(true);
    setForm((prevOptions) => {
      const filteredOptions = prevOptions.filter(
        (opt) => opt.id !== draggedOption.id
      );

      const targetIndex = filteredOptions.findIndex(
        (opt) => opt.id === targetOption.id
      );

      if (targetIndex === -1) return prevOptions; // Return early if target not found

      const targetGroupId = targetOption.groupId;
      const targetGroupOptions = filteredOptions.filter(
        (option) => option.groupId === targetGroupId
      );
      const draggedGroupedOptions = filteredOptions.filter(
        (option) =>
          option.groupId === draggedOption.groupId &&
          option.groupId !== targetGroupId
      );
      const otherOptions = filteredOptions.filter(
        (option) =>
          option.groupId !== targetGroupId &&
          option.groupId !== draggedOption.groupId
      );

      const insertIndex =
        draggedOption.seq === null || draggedOption.seq > targetOption.seq
          ? targetIndex // Insert before target
          : targetIndex + 1; // Insert after target

      // Create a new array with the dragged option inserted
      const updatedOptions = [
        ...targetGroupOptions.slice(0, insertIndex),
        draggedOption,
        ...targetGroupOptions.slice(insertIndex),
      ];
      // Update seq for all options, ensuring they are sequential from 1 to n
      return [
        ...otherOptions,
        ...updatedOptions.map((opt, index) => ({ ...opt, seq: index + 1 })),
        ...draggedGroupedOptions
          .sort((a, b) => a.seq - b.seq)
          .map((opt, index) => ({ ...opt, seq: index + 1 })),
      ];
    });
  };

  const updateGroup = (
    draggedOption: TQuestionOption,
    newGroupId: number | null
  ) => {
    setTouched(true);
    setForm((prevOptions) => {
      const filteredOptions = prevOptions.filter(
        (opt) => opt.id !== draggedOption.id
      );
      const targetGroupedOptions = [
        ...filteredOptions.filter((option) => option.groupId === newGroupId),
        { ...draggedOption, seq: 1, groupId: newGroupId },
      ];
      const draggedGroupedOptions = filteredOptions.filter(
        (option) =>
          option.groupId === draggedOption.groupId &&
          option.groupId !== newGroupId
      );
      const otherOptions = filteredOptions.filter(
        (option) =>
          option.groupId !== draggedOption.groupId &&
          option.groupId !== newGroupId
      );

      return [
        ...otherOptions,
        ...draggedGroupedOptions
          .sort((a, b) => a.seq - b.seq)
          .map((opt, index) => ({
            ...opt,
            seq: index + 1,
          })),
        ...targetGroupedOptions
          .sort((a, b) => a.seq - b.seq)
          .map((opt, index) => ({
            ...opt,
            seq: index + 1,
          })),
      ];
    });
  };

  if (isLoading) return <Loader />;

  return (
    <DndProvider backend={HTML5Backend}>
      <section className={styles.section}>
        <BackArrowButton
          text={"למסך הקודם"}
          position={"center"}
          onClick={() => navigate(-1)}
        />
        <div className={styles.header}>
          <button
            type={"button"}
            className={`${stylesQuestionnaire.addBtn} ${styles.form__btn} ${styles.form__btn_add}`}
            onClick={() => {
              setShowAddNewOptionForm(true);
            }}
          >
            הוסף אופציה
            <span className={styles.plus}>+</span>
          </button>
          <h1 className={styles.section__title}>אופציות </h1>
          <button
            type={"button"}
            className={`${stylesQuestionnaire.addBtn} ${styles.form__btn} ${styles.form__btn_add}`}
            onClick={() => {
              setShowAddNewGroupForm(true);
            }}
          >
            הוסף קקבוצה
            <span className={styles.plus}>+</span>
          </button>
        </div>
        {showAddNewGroupForm && id && (
          <AddOrEditGroupForm
            id={id}
            setShowAddNewGroupForm={setShowAddNewGroupForm}
            setOpenModalSuccess={setOpenModalSuccess}
            setOpenErrorModal={setOpenErrorModal}
          />
        )}
        {options.length > 0 || showAddNewOptionForm || showAddNewGroupForm ? (
          <ul className={`${styles.options}`}>
            <>
              <div className={styles.applyToAllQuestionsContainer}>
                <label
                  htmlFor={"applyToAllQuestions"}
                  className={styles.option_title}
                >
                  <input
                    type={"checkbox"}
                    name={"applyToAllQuestions"}
                    id={"applyToAllQuestions"}
                    onChange={() => {
                      setApplyToAllQuestions((prev) => !prev);
                    }}
                    checked={applyToAllQuestions}
                  />
                  לבחור את השאלה הבאה עבור כל השאלות
                </label>
                {applyToAllQuestions && (
                  <Select
                    name={"nextQuestionId"}
                    options={nextQuestionOptions}
                    placeholder={"אנא בחר שאלה הבאה"}
                    className={`${styles.select} `}
                    onChange={(value) => {
                      setTouched(true);
                      value &&
                        setForm((prev) =>
                          prev.map((o) => ({ ...o, nextQuestionId: value.id }))
                        );
                    }}
                  />
                )}
              </div>

              <li className={`${styles.option} ${styles.option_title}`}>
                <span
                  className={`${styles.form__noMargin} ${styles.form__bold}`}
                >
                  מס':
                </span>
                <span
                  className={`${styles.form__noMargin} ${styles.form__bold}`}
                >
                  סדר:
                </span>
                <span
                  className={`${styles.form__noMargin} ${styles.form__bold}`}
                >
                  תאור:
                </span>
                <span
                  className={`${styles.form__noMargin} ${styles.form__bold}`}
                >
                  שאלה הבאה:
                </span>
                <span
                  className={`${styles.form__noMargin} ${styles.form__bold}`}
                >
                  משקולת:
                </span>
              </li>

              {showAddNewOptionForm && id && (
                <AddNewOption
                  id={id}
                  groupsSelectOptions={groupsSelectOptions}
                  setShowAddNewOptionForm={setShowAddNewOptionForm}
                  setOpenModalSuccess={setOpenModalSuccess}
                  setOpenErrorModal={setOpenErrorModal}
                />
              )}
              {groupsState.length > 0 &&
                groupsState.map((g) => (
                  <DraggableGroup
                    key={g.id}
                    group={g}
                    moveGroup={moveGroup}
                    updateGroup={updateGroup}
                  >
                    {form
                      .filter((o) => o.groupId === g.id)
                      ?.sort((a, b) => a.seq - b.seq)
                      .map((opt) => (
                        <DraggableOption
                          key={opt.id}
                          option={opt}
                          groupId={opt.groupId}
                          moveOption={moveOption}
                          updateGroup={updateGroup}
                          updateForm={updateForm}
                        />
                      ))}
                  </DraggableGroup>
                ))}
              <p className={styles.group}>ללא קבוצה</p>
              {form.find((o) => !o.groupId) ? (
                form
                  .filter((o) => !o.groupId)
                  .sort((a, b) => a.seq - b.seq)
                  .map((opt) => (
                    <DraggableOption
                      key={opt.id}
                      option={opt}
                      groupId={opt.groupId}
                      moveOption={moveOption}
                      updateGroup={updateGroup}
                      updateForm={updateForm}
                    />
                  ))
              ) : (
                <p>אין אופציות ללא קבוצה</p>
              )}
            </>
          </ul>
        ) : (
          <p>תרם נוספו אופציות</p>
        )}
        {form.length > 0 && (
          <button
            type={"submit"}
            className={`button ${styles.submitBtn} ${
              !touched && formsStyles.form__btn_disabled
            } `}
            disabled={!touched}
            onClick={onSubmit}
          >
            {isLoading ? "שליחה" : "שמור שינויים"}
          </button>
        )}
        {openModalSuccess && (
          <Modal onClose={() => setOpenModalSuccess(false)}>
            <GeneralSuccessPopup
              onClose={() => {
                setOpenModalSuccess(false);
              }}
            />
          </Modal>
        )}
        {openErrorModal && (
          <Modal
            onClose={() => {
              setOpenErrorModal(false);
            }}
          >
            <ErrorPopup
              onClose={() => {
                setOpenErrorModal(false);
              }}
            />
          </Modal>
        )}
      </section>
    </DndProvider>
  );
}

export default AddOrEditOptions;
