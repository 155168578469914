import {
  IBooleanOption,
  IOption,
  TAddQuestionForm,
  TLabeledItem,
  TQuestionnaireTypeOption,
  TReportQuestionOption,
} from "../types/types";
import {
  ICompanyGroupsRRM,
  ICompanyGroupsRRMHeaders,
  IQuestion,
  IQuestionnaireData,
  TAddExerciseRequest,
  TAddFeatureRequest,
  TGroup,
  TQuestionOption,
} from "../services/types/utils.types";
import { StylesConfig } from "react-select";
import {
  TQuestionnaireEntry,
  TRRMListEntry,
} from "../services/types/user.types";

export const TEST_BASE_URL = "https://api-test.bpreven.com/";
export const BASE_URL = "https://api.bpreven.com/";
// export const BASE_URL = "http://localhost:7241/";
// // export const protocolsUrl = "https://bprevenserver.dgtracking.co.il";
// export const protocolsUrl = "http://localhost:3001";

export const mobileWidth = "(max-width: 600px)";
export const actionReportId = 27;

export const searchOptions: TLabeledItem[] = [
  { value: "Desc", label: "שם" },
  { value: "Symbol", label: "סימן" },
  { value: "StartQuestion", label: "שאלה ראשונית" },
  { value: "Status", label: "סטטוס" },
];
export const searchFeaturesOptions: TLabeledItem[] = [
  { value: "all", label: "הכל" },
  { value: "Symbol", label: "סימן" },
  { value: "[Desc]", label: "שם" },
  { value: "Number", label: "מספר" },
];
export const searchLibraryOptions: TLabeledItem[] = [
  { value: "all", label: "הכל" },
  { value: "Exercises.Symbol", label: "סימן" },
  { value: "Exercises.About", label: "תיאור" },
  { value: "Exercises.StatusId", label: "סטטוס" },
  { value: "Exercises.Title", label: "כותרת" },
];

export const questionnaireTypes: TQuestionnaireTypeOption[] = [
  { value: "Q", label: "רגיל", id: 1, Symbol: "Q", Name: "רגיל" },
  { value: "QR", label: "הרשמה", id: 2, Symbol: "QR", Name: "הרשמה" },
  {
    value: "QG",
    label: "כניסת אורח ראשוני",
    id: 3,
    Symbol: "QG",
    Name: "כניסת אורח ראשוני",
  },
  {
    value: "QT",
    label: "שאלון מטפלים",
    id: 4,
    Symbol: "QT",
    Name: "שאלון מטפלים",
  },
];
export const nextQuestionOptionsInitialState = [
  { value: "OptionId", label: "לפי אופציות" },
];
export const yesNoOptions: TLabeledItem[] = [
  { value: "0", label: "לא" },
  { value: "1", label: "כן" },
];
export const statusOptions: TLabeledItem[] = [
  { value: "1", label: "פעיל" },
  { value: "0", label: "לא פעיל" },
];
export const periodOptions: TLabeledItem[] = [
  { value: "Monthly", label: "שאלון חודשי" },
  { value: "Dayly", label: "שאלון יומי" },
  { value: "Default", label: "ערך דפולטיבי" },
];
export const typeDataOptions: TLabeledItem[] = [
  { value: "1", label: "אופציות" },
  { value: "2", label: "טקסט" },
  { value: "3", label: "מספר" },
  { value: "4", label: "תאריך" },
  { value: "5", label: "משפט" },
];
export const addOrEditQuestionnaireInitialState: Partial<IQuestionnaireData> = {
  id: 0,
  desc: "",
  symbol: "",
  statusId: 1,
  factorQuestionnaire: 0,
};
export const addNewFeatureInitialState: TAddFeatureRequest = {
  Name: "",
  Symbol: "",
  number: 0,
};
export const addNewExerciseInitialState: TAddExerciseRequest = {
  Symbol: "",
  Title: "",
  categoryId: 0,
  descrip: "",
  link: "",
  status: true,
};

export const addNewQuestionInitialState: TAddQuestionForm = {
  DescQ: "",
  IsEnd: false,
  NameQ: "",
  StatusId: 1,
  typeData: "OptionId",
  DescDataType: "OptionId",
};
export const editQuestionFormInitialState: Partial<IQuestion> = {
  desc: "",
  id: 0,
  nextQuestionId: 0,
  questionnaireId: 0,
  dataTypesId: 1,
  seq: 1,
  statusId: 1,
  factorQuestion: 0,
  companyGroupsRRMHeadersId: 0,
  optionsCountMin: 0,
  optionsCountMax: 0,
  isSpecific: false,
};
export const questionOptionInitialState: TQuestionOption = {
  id: 0,
  desc: "",
  questionsId: 0,
  groupId: null,
  groups: null,
  nextQuestionId: 0,
  nextQuestions: null,
  seq: 0,
  factorAnswer: null,
  statusId: 1,
};
export const questionGroupInitialState: TGroup = {
  id: 0,
  name: "",
  questionsId: 0,
  seq: 0,
  factorGroup: 0,
  statusId: 1,
  optionsCountMin: 0,
  optionsCountMax: null,
};

export const findLatestQuestionnaire = (
  id: number,
  questionnaires: TQuestionnaireEntry[]
): TQuestionnaireEntry | undefined => {
  if (questionnaires.length === 0) return undefined;
  const temp = questionnaires.filter((q) => q.QuestionnaireId === id);
  return temp.length
    ? temp
        .filter((q) => q.QuestionnaireId === id)
        .reduce((latest, current) => {
          const latestDate = new Date(latest.TimeStamp);
          const currentDate = new Date(current.TimeStamp);
          return currentDate > latestDate ? current : latest;
        })
    : undefined;
};

export const RRMListDefault: TRRMListEntry[] = [
  {
    Id: 1,
    Title: "A",
    Desc: null,
    Color: "#5d3cc7",
    Min: 0,
    Max: 0,
    Anliza: "",
    Recommendations: "",
    Highlights: "",
    Conversation: "",
  },
  {
    Id: 2,
    Title: "B",
    Desc: null,
    Color: "#4f0080",
    Min: 0,
    Max: 0,
    Anliza: "",
    Recommendations: "",
    Highlights: "",
    Conversation: "",
  },
  {
    Id: 3,
    Title: "C",
    Desc: null,
    Color: "#fffe02",
    Min: 0,
    Max: 0,
    Anliza: "",
    Recommendations: "",
    Highlights: "",
    Conversation: "",
  },
  {
    Id: 4,
    Title: "D",
    Desc: null,
    Color: "#ff7654",
    Min: 0,
    Max: 0,
    Anliza: "",
    Recommendations: "",
    Highlights: "",
    Conversation: "",
  },
  {
    Id: 5,
    Title: "E",
    Desc: null,
    Color: "#ff7803",
    Min: 0,
    Max: 0,
    Anliza: "",
    Recommendations: "",
    Highlights: "",
    Conversation: "",
  },
  {
    Id: 6,
    Title: "F",
    Desc: null,
    Color: "#cd2701",
    Min: 0,
    Max: 0,
    Anliza: "",
    Recommendations: "",
    Highlights: "",
    Conversation: "",
  },
];

export const noDataLabel = "אין מידע";

export const selectCustomStyles: StylesConfig<IOption, false> = {
  control: (provided) => ({
    ...provided,
    background: "#fff",
    borderColor: "#9e9e9e",
    minHeight: "24px",
    height: "24px",
  }),

  valueContainer: (provided) => ({
    ...provided,
    height: "24px",
    padding: "0 6px",
  }),

  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "24px",
  }),
};

export function calculateAge(birthDateString: string): number {
  const today = new Date();
  const birthDate = new Date(birthDateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  // Check if the birth month hasn't come yet this year, or if it's the same month but the day hasn't come yet
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
}

export function findMinAndMax(items: TRRMListEntry[] | null): {
  min: number;
  max: number;
} {
  if (!items || items.length === 0) {
    throw new Error("The array is empty");
  }

  let min = items[0].Min;
  let max = items[0].Max;

  for (const item of items) {
    if (item.Min < min) {
      min = item.Min;
    }
    if (item.Max > max) {
      max = item.Max;
    }
  }

  return { min, max };
}

export function filterLastOfEachGroup(
  options: TLabeledQuestionOption[]
): TLabeledQuestionOption[] {
  if (!options.length || !options[0]?.GroupId) {
    return options;
  } else {
    const groupMap = options.reduce<Record<number, TLabeledQuestionOption>>(
      (acc, item) => {
        // @ts-ignore
        acc[item.GroupId] = item; // Overwrites previous entry with same GroupId with the current item
        return acc;
      },
      {}
    );

    return Object.values(groupMap);
  }
}

export const getSelectNote = (
  min: number | null,
  max: number | null
): string => {
  let text = "";
  if (!min) return text;
  if (min) {
    min === 1
      ? max === 1
        ? (text = "*יש לבחור אפשרות אחת")
        : (text = "*יש לבחור אפשרות אחת לפחות")
      : (text = `*יש לבחור לפחות ${min} אפשרויות`);
  }
  return text;
};

export const isFormValid = (form: {
  [key: string]: {
    isValid: boolean;
  };
}): boolean => {
  return Object.keys(form).every((k) => form[k].isValid);
};

export type TLabeledQuestionOption = IOption & TReportQuestionOption;
export type TGrouppedQuestionsOptions = {
  label: string;
  options: TLabeledQuestionOption[];
};

export const transformServerOptions = (
  optionsArray: TReportQuestionOption[],
  count: number | null,
  min: null | number
): Array<TGrouppedQuestionsOptions | TLabeledQuestionOption> => {
  const groups: Record<string, TGrouppedQuestionsOptions> = {};
  const noGroup: TLabeledQuestionOption[] = [];

  optionsArray.forEach((option) => {
    if (option.GroupId) {
      const groupKey = `group-${option.GroupId}`;
      if (!groups[groupKey]) {
        groups[groupKey] = {
          label: option.Groups ? option.Groups.Name : "Default Group",
          options: [],
        };
      }
      groups[groupKey].options.push({
        ...option,
        count,
        min,
        value: option.Id.toString(),
        label: option.Desc,
      });
    } else {
      noGroup.push({
        ...option,
        count,
        min,
        value: option.Id.toString(),
        label: option.Desc,
      });
    }
  });
  for (const groupKey in groups) {
    if (groups[groupKey].options) {
      groups[groupKey].options.sort((a, b) => a.Seq - b.Seq);
    }
  }
  noGroup.sort((a, b) => a.Seq - b.Seq);
  return [...Object.values(groups), ...noGroup];
};

// Type guard function for TGrouppedQuestionsOptions
export function isTGrouppedQuestionsOptions(
  object: TGrouppedQuestionsOptions | TLabeledQuestionOption
): object is TGrouppedQuestionsOptions {
  return typeof object === "object" && object !== null && "options" in object;
}

export const initialQuestionState: Partial<IQuestion> = {
  id: 0,
  seq: 0,
  desc: "",
  statusId: 1,
  // isEnd: false,
  // descQuestionnaire: "",
  // typeQ: 0,
  // descDataType: "",
  // nextQuestionDesc: "",
};

export const ITEM_TYPE = {
  GROUP: "GROUP",
  OPTION: "OPTION",
  QUESTION: "QUESTION",
};

export const initialCompanyGroupsRRMHeaders: ICompanyGroupsRRMHeaders = {
  id: 0,
  companyId: 0,
  questionnaireId: 0,
  desc: "",
  isClini: false,
  isDefault: false,
  statusId: 1,
};

export const initialCompanyGroupsRRM: ICompanyGroupsRRM = {
  id: 0,
  companyId: 0,
  questionnaireId: null,
  companyGroupsRRMHeadersId: null,
  title: "",
  desc: "",
  color: "",
  anliza: "",
  recommendations: "",
  highlights: "",
  conversation: "",
  start: 0,
  end: 0,
  statusId: 1,
};
export const RRMHeadersTypesOptions: IBooleanOption[] = [
  { label: "קליני", value: true },
  { label: "רגיל", value: false },
];
