import styles from "./addOrEditOption.module.css";
import optionsPageStyles from "../../../pages/addOrEditOptions/addOrEditOptions.module.css";
import formStyles from "../forms.module.css";
import Select from "react-select";
import React from "react";
import { TQuestionOption } from "../../../services/types/utils.types";
import { useAppSelector } from "../../../services/hooks";
import { nextQuestionOptionsSelector } from "../../../services/selectors/utils.selectors";

function AddOrEditOptionForm({
  onFormChange,
  form,
}: {
  onFormChange: (updatedOption: TQuestionOption) => void;
  form: TQuestionOption | undefined;
}) {
  const nextQuestionOptions = useAppSelector(nextQuestionOptionsSelector);

  if (!form) return null;
  return (
    <li className={optionsPageStyles.option}>
      <p className={styles.form__noMargin}>{form.id}</p>
      <input
        type={"number"}
        className={`${formStyles.form__input}`}
        name="seq"
        id="seq"
        value={form.seq}
        onChange={(e) => onFormChange({ ...form, seq: Number(e.target.value) })}
      />
      <textarea
        className={`${formStyles.form__input}`}
        name="desc"
        id="desc"
        value={form.desc}
        onChange={(e) => onFormChange({ ...form, desc: e.target.value })}
      />
      <Select
        name={"nextQuestionId"}
        options={nextQuestionOptions}
        value={nextQuestionOptions.find((o) => o.id === form.nextQuestionId)}
        placeholder={"אנא בחר שאלה הבאה"}
        className={`${styles.select} `}
        onChange={(value) => {
          onFormChange({ ...form, nextQuestionId: value?.id || null });
        }}
      />
      <input
        type={"number"}
        className={`${formStyles.form__input}`}
        name="factorAnswer"
        id="factorAnswer"
        value={form.factorAnswer || 0}
        onChange={(e) =>
          onFormChange({ ...form, factorAnswer: Number(e.target.value) })
        }
      />
    </li>
  );
}

export default AddOrEditOptionForm;
