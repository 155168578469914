import popupStyles from "./qFactorsPopup.module.css";
import { IPopupWithoutProps } from "../../../types/types";
import { useAppSelector } from "../../../services/hooks";
import { questionnairesSelector } from "../../../services/selectors/utils.selectors";
import { noDataLabel } from "../../../utils/constants";

function QFactorsPopup({ onClose }: IPopupWithoutProps) {
  const questionnaires = useAppSelector(questionnairesSelector);
  return (
    <article className={popupStyles.popup}>
      <h2 className={popupStyles.popup__title}> משקולות שאלונים</h2>
      {questionnaires.length > 0 ? (
        <ul className={popupStyles.popup__list}>
          <li
            className={`${popupStyles.popup__li} ${popupStyles.popup__li_header}`}
          >
            <span>מספר:</span>
            <span>שם:</span>
            <span>סימן:</span>
            <span>משקולת:</span>
          </li>
          {questionnaires.map((q, index) => (
            <li
              className={`${popupStyles.popup__li} ${
                index % 2 === 0 && popupStyles.popup__li_colored
              }`}
              key={q.id}
            >
              <span>{q.id}</span>
              <span>{q.desc}</span>
              <span>{q.symbol}</span>
              <span>{q.factorQuestionnaire}</span>
            </li>
          ))}
        </ul>
      ) : (
        <p>{noDataLabel}</p>
      )}
      <button
        className={`button hover ${popupStyles.popup__nextBtn}`}
        onClick={onClose}
      >
        אישור
      </button>
    </article>
  );
}

export default QFactorsPopup;
