import { api } from "./api";
import { TEST_BASE_URL } from "../../utils/constants";
import {
  ICompanyGroupsRRM,
  ICompanyGroupsRRMHeaders,
  IQuestion,
  IQuestionnaireData,
  TGroup,
  TQuestionOption,
} from "../types/utils.types";

export const utilsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getQuestionnaires: build.mutation<
      IQuestionnaireData[],
      { companyId: number }
    >({
      query: ({ companyId }) => ({
        url: `${TEST_BASE_URL}Admintech/Admin_Questionnaire_DML/1/${companyId}`,
        method: "POST",
        body: { questionnaire: true },
      }),
    }),
    addOrEditQuestionnaire: build.mutation<
      IQuestionnaireData[],
      { companyId: number; body: Partial<IQuestionnaireData> }
    >({
      query: ({ companyId, body }) => ({
        url: `${TEST_BASE_URL}Admintech/Admin_Questionnaire_DML/2/${companyId}`,
        method: "POST",
        body,
      }),
    }),
    deleteQuestionnaire: build.mutation<
      IQuestionnaireData[],
      { companyId: number; id: number }
    >({
      query: ({ companyId, id }) => ({
        url: `${TEST_BASE_URL}Admintech/Admin_Questionnaire_DML/3/${companyId}`,
        method: "POST",
        body: { id },
      }),
    }),
    getQuestions: build.mutation<IQuestion[], { questionnaireId: string }>({
      query: ({ questionnaireId }) => ({
        url: `${TEST_BASE_URL}Admintech/Admin_Questions_DML/1/${questionnaireId}`,
        method: "POST",
        body: [],
      }),
    }),
    addOrEditQuestions: build.mutation<
      IQuestion[],
      { questionnaireId: string; questions: Partial<IQuestion>[] }
    >({
      query: ({ questionnaireId, questions }) => ({
        url: `${TEST_BASE_URL}Admintech/Admin_Questions_DML/2/${questionnaireId}`,
        method: "POST",
        body: questions,
      }),
    }),
    deleteQuestions: build.mutation<
      IQuestion[],
      { questionnaireId: string | number; questionId: string | number }
    >({
      query: ({ questionnaireId, questionId }) => ({
        url: `${TEST_BASE_URL}Admintech/Admin_Questions_DML/3/${questionnaireId}`,
        method: "POST",
        body: [{ id: questionId }],
      }),
    }),
    getQuestionsOptions: build.mutation<
      TQuestionOption[],
      { questionsId: number | string }
    >({
      query: ({ questionsId }) => ({
        url: `${TEST_BASE_URL}Admintech/Admin_QuestionsOptions_DML/1/${questionsId}`,
        method: "POST",
        body: [],
      }),
    }),
    addOrEditQuestionsOptions: build.mutation<
      TQuestionOption[],
      { questionsId: number | string; options: TQuestionOption[] }
    >({
      query: ({ questionsId, options }) => ({
        url: `${TEST_BASE_URL}Admintech/Admin_QuestionsOptions_DML/2/${questionsId}`,
        method: "POST",
        body: options,
      }),
    }),
    deleteQuestionsOptions: build.mutation<
      TQuestionOption[],
      { questionsId: number | string; optionsId: string | number }
    >({
      query: ({ questionsId, optionsId }) => ({
        url: `${TEST_BASE_URL}Admintech/Admin_QuestionsOptions_DML/3/${questionsId}`,
        method: "POST",
        body: [{ id: optionsId }],
      }),
    }),
    getQuestionsGroups: build.mutation<
      TGroup[],
      { questionsId: number | string }
    >({
      query: ({ questionsId }) => ({
        url: `${TEST_BASE_URL}Admintech/Admin_Groups_DML/1/${questionsId}`,
        method: "POST",
        body: [],
      }),
    }),
    addOrEditQuestionsGroups: build.mutation<
      TGroup[],
      { questionsId: string | number; groups: TGroup[] }
    >({
      query: ({ questionsId, groups }) => ({
        url: `${TEST_BASE_URL}Admintech/Admin_Groups_DML/2/${questionsId}`,
        method: "POST",
        body: groups,
      }),
    }),
    deleteQuestionsGroups: build.mutation<
      TGroup[],
      { questionsId: string | number; groupId: string | number }
    >({
      query: ({ questionsId, groupId }) => ({
        url: `${TEST_BASE_URL}Admintech/Admin_Groups_DML/3/${questionsId}`,
        method: "POST",
        body: [{ id: groupId }],
      }),
    }),
    getCompanyGroupsRRMHeaders: build.mutation<
      ICompanyGroupsRRMHeaders[],
      { companyId: number }
    >({
      query: ({ companyId }) => ({
        url: `${TEST_BASE_URL}Admintech/Admin_CompanyGroupsRRMHeaders_DML/1/${companyId}`,
        method: "POST",
        body: {},
      }),
    }),
    addOrEditCompanyGroupsRRMHeaders: build.mutation<
      ICompanyGroupsRRMHeaders[],
      { companyId: string | number; RRMHeaders: ICompanyGroupsRRMHeaders }
    >({
      query: ({ companyId, RRMHeaders }) => ({
        url: `${TEST_BASE_URL}Admintech/Admin_CompanyGroupsRRMHeaders_DML/2/${companyId}`,
        method: "POST",
        body: RRMHeaders,
      }),
    }),
    deleteCompanyGroupsRRMHeaders: build.mutation<
      ICompanyGroupsRRMHeaders[],
      { companyId: string | number; RRMHeadersId: string | number }
    >({
      query: ({ companyId, RRMHeadersId }) => ({
        url: `${TEST_BASE_URL}Admintech/Admin_CompanyGroupsRRMHeaders_DML/3/${companyId}`,
        method: "POST",
        body: [{ id: RRMHeadersId }],
      }),
    }),
    getCompanyGroupsRRM: build.mutation<
      ICompanyGroupsRRM[],
      { companyGroupsRRMHeadersId: number }
    >({
      query: ({ companyGroupsRRMHeadersId }) => ({
        url: `${TEST_BASE_URL}Admintech/Admin_CompanyGroupsRRM_DML/1/${companyGroupsRRMHeadersId}`,
        method: "POST",
        body: {},
      }),
    }),
    addOrEditCompanyGroupsRRM: build.mutation<
      ICompanyGroupsRRM[],
      {
        companyGroupsRRMHeadersId: string | number;
        RRMGroup: ICompanyGroupsRRM;
      }
    >({
      query: ({ companyGroupsRRMHeadersId, RRMGroup }) => ({
        url: `${TEST_BASE_URL}Admintech/Admin_CompanyGroupsRRM_DML/2/${companyGroupsRRMHeadersId}`,
        method: "POST",
        body: RRMGroup,
      }),
    }),
    deleteCompanyGroupsRRM: build.mutation<
      ICompanyGroupsRRM[],
      {
        companyGroupsRRMHeadersId: string | number;
        RRMGroupsId: string | number;
      }
    >({
      query: ({ companyGroupsRRMHeadersId, RRMGroupsId }) => ({
        url: `${TEST_BASE_URL}Admintech/Admin_CompanyGroupsRRM_DML/3/${companyGroupsRRMHeadersId}`,
        method: "POST",
        body: [{ id: RRMGroupsId }],
      }),
    }),
  }),
});

export const {
  useGetQuestionnairesMutation,
  useDeleteQuestionnaireMutation,
  useDeleteQuestionsMutation,
  useAddOrEditQuestionnaireMutation,
  useAddOrEditQuestionsMutation,
  useGetQuestionsMutation,
  useGetQuestionsOptionsMutation,
  useAddOrEditQuestionsOptionsMutation,
  useGetQuestionsGroupsMutation,
  useAddOrEditQuestionsGroupsMutation,
  useGetCompanyGroupsRRMHeadersMutation,
  useAddOrEditCompanyGroupsRRMHeadersMutation,
  useGetCompanyGroupsRRMMutation,
  useAddOrEditCompanyGroupsRRMMutation,
} = utilsApi;
