import AddOrEditOptionForm from "../forms/addOrEditOption/addOrEditOptionForm";
import Select from "react-select";
import styles from "./addNewOption.module.css";
import addOrEditOptionsStyles from "../../pages/addOrEditOptions/addOrEditOptions.module.css";
import React, { Dispatch, SetStateAction, useState } from "react";
import { questionOptionInitialState } from "../../utils/constants";
import { TQuestionOption } from "../../services/types/utils.types";
import { INumberOption } from "../../types/types";
import { useAddOrEditQuestionsOptionsMutation } from "../../services/api/utils.api";

interface IAddNewOptionProps {
  id: string;
  groupsSelectOptions: INumberOption[];
  setShowAddNewOptionForm: Dispatch<SetStateAction<boolean>>;
  setOpenModalSuccess: Dispatch<SetStateAction<boolean>>;
  setOpenErrorModal: Dispatch<SetStateAction<boolean>>;
}

function AddNewOption({
  id,
  groupsSelectOptions,
  setShowAddNewOptionForm,
  setOpenModalSuccess,
  setOpenErrorModal,
}: IAddNewOptionProps) {
  const [addOptionForm, setAddOptionForm] = useState<TQuestionOption>(
    questionOptionInitialState
  );
  const [addOrEditOptions] = useAddOrEditQuestionsOptionsMutation();

  const addNewOption = () => {
    id &&
      addOrEditOptions({
        questionsId: id,
        options: [{ ...addOptionForm, questionsId: Number(id) }],
      })
        .then((res) => {
          if ("data" in res) {
            setAddOptionForm(questionOptionInitialState);
            setShowAddNewOptionForm(false);
            setOpenModalSuccess(true);
          }
        })
        .catch((err) => {
          setOpenErrorModal(true);
          console.log(err);
        });
  };

  return (
    <>
      <AddOrEditOptionForm
        form={addOptionForm}
        onFormChange={(updatedOption) => setAddOptionForm(updatedOption)}
      />
      <div className={`${styles.selectContainer}`}>
        <span className={`${styles.label}`}>לשייך לקבוצה:</span>
        <Select
          name={"groups"}
          options={groupsSelectOptions}
          placeholder={"אנא בחר..."}
          className={styles.select}
          onChange={(value) => {
            value &&
              setAddOptionForm((prev) => ({
                ...prev,
                groupId: value.value,
              }));
          }}
        />
      </div>
      <button
        type={"submit"}
        className={`button ${addOrEditOptionsStyles.submitBtn} ${
          !addOptionForm.desc && addOrEditOptionsStyles.submitBtn_disabled
        } `}
        disabled={!addOptionForm.desc}
        onClick={addNewOption}
      >
        {"לשמור בבסיס הנתונים"}
      </button>
    </>
  );
}

export default AddNewOption;
