import { RootState } from "../types";

export const questionnairesSelector = (state: RootState) =>
  state.utils.questionnaires;
export const questionsSelector = (state: RootState) => state.utils.questions;
export const nextQuestionOptionsSelector = (state: RootState) =>
  state.utils.nextQuestionOptions;
export const questionsSortedByQuestionnaireSelector = (state: RootState) =>
  state.utils.questionsSortedByQuestionnaire;
export const dataTypeSelector = (state: RootState) => state.utils.dataType;
export const questionOptionsSelector = (state: RootState) =>
  state.utils.questionOptions;
export const questionOptionsGrouppedSelector = (state: RootState) =>
  state.utils.questionOptionsGroupped;
export const companyGroupsRRMHeadersSelector = (state: RootState) =>
  state.utils.companyGroupsRRMHeaders;
export const companyGroupsRRMSelector = (state: RootState) =>
  state.utils.companyGroupsRRM;
export const featuresSelector = (state: RootState) => state.utils.features;
export const groupsSelector = (state: RootState) => state.utils.groups;
export const exercisesSelector = (state: RootState) => state.utils.exercises;
export const categoriesSelector = (state: RootState) =>
  state.utils.libraryCategories;
