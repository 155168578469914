import styles from "../forms.module.css";
import questionnaireStyles from "../../../pages/questionnaires/questionnaires.module.css";
import rrmHeadersStyles from "./addOrEditRRMHeaders.module.css";
import Select, { ActionMeta, SingleValue } from "react-select";
import {
  initialCompanyGroupsRRMHeaders,
  RRMHeadersTypesOptions,
  statusOptions,
} from "../../../utils/constants";
import { IBooleanOption, TLabeledItem } from "../../../types/types";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  useAddOrEditCompanyGroupsRRMHeadersMutation,
  useGetCompanyGroupsRRMMutation,
} from "../../../services/api/utils.api";
import { useAppSelector } from "../../../services/hooks";
import Modal from "../../modals/modal/Modal";
import GeneralSuccessPopup from "../../modals/generalSuccessPopup/generalSuccessPopup";
import ErrorPopup from "../../modals/errorPopup/errorPopup";
import { adminSelector } from "../../../services/selectors/user.selectors";
import { companyGroupsRRMHeadersSelector } from "../../../services/selectors/utils.selectors";
import AddOrEditGroupsRRMForm from "../addOrEditGroupsRRM/addOrEditGroupsRRMForm";
import RrmModel from "../../rrmModel/rrmModel";
import { ICompanyGroupsRRM } from "../../../services/types/utils.types";

function AddOrEditRRMHeaders({
  questionnaireId,
  rrmId,
}: {
  questionnaireId: string | number;
  setShowAddOrEditRRMForm: Dispatch<SetStateAction<boolean>>;
  setRRMToEditId: Dispatch<SetStateAction<null | number>>;
  rrmId: null | number;
}) {
  const admin = useAppSelector(adminSelector);
  const rrmHeaders = useAppSelector(companyGroupsRRMHeadersSelector);

  const [form, setForm] = useState(initialCompanyGroupsRRMHeaders);
  const [touched, setTouched] = useState(false);
  const [showGroupsRRMForm, setShowGroupsRRMForm] = useState(false);
  const [rrmGroupToEdit, setRrmGroupToEdit] =
    useState<null | ICompanyGroupsRRM>(null);

  console.log(rrmId, form, rrmHeaders);

  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);

  const [addOrEditRRMHeaders, { isLoading }] =
    useAddOrEditCompanyGroupsRRMHeadersMutation();
  const [getGroupsRRM] = useGetCompanyGroupsRRMMutation();

  useEffect(() => {
    if (rrmId) {
      getGroupsRRM({ companyGroupsRRMHeadersId: rrmId });
    }
  }, [rrmId, getGroupsRRM]);

  useEffect(() => {
    if (rrmId) {
      const rrmHeader = rrmHeaders.find((h) => h.id === rrmId);
      if (rrmHeader) {
        setForm((prev) => ({ ...prev, ...rrmHeader }));
      }
    }
  }, [rrmId, rrmHeaders]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTouched(true);
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };
  const onSelectChange = (
    value: SingleValue<TLabeledItem | IBooleanOption>,
    { name }: ActionMeta<TLabeledItem | IBooleanOption>
  ) => {
    setTouched(true);
    setForm((prev) => ({ ...prev, [name as string]: value?.value }));
  };

  return (
    <section className={rrmHeadersStyles.section}>
      <form className={styles.form}>
        <ul className={styles.form__list}>
          <li>
            <label htmlFor="desc" className={styles.form__label}>
              שם
            </label>
            <input
              type={"text"}
              className={`${styles.form__input}`}
              name="desc"
              id="desc"
              value={form.desc || ""}
              onChange={handleChange}
            />
          </li>
          <li>
            <label htmlFor="statusId" className={styles.form__label}>
              סטטוס
            </label>
            <Select
              name={"statusId"}
              options={statusOptions}
              value={form.statusId === 1 ? statusOptions[0] : statusOptions[1]}
              placeholder={"אנא בחר סוג סטטוס"}
              className={styles.select}
              onChange={onSelectChange}
            />
          </li>
          <li>
            <label htmlFor="isClini" className={styles.form__label}>
              סוג
            </label>
            <Select
              name={"isClini"}
              options={RRMHeadersTypesOptions}
              value={
                form?.isClini
                  ? RRMHeadersTypesOptions.find((t) => t.value)
                  : RRMHeadersTypesOptions.find((t) => !t.value)
              }
              placeholder={"אנא בחר סוג RRM"}
              className={styles.select}
              onChange={onSelectChange}
            />
          </li>
          <li>
            <input
              type={"checkbox"}
              name="isDefault"
              id="isDefault"
              checked={form.isDefault || false}
              onChange={() =>
                setForm((prev) => ({ ...prev, isDefault: !prev.isDefault }))
              }
            />
            <label htmlFor="isDefault" className={styles.form__label}>
              האם דפולטיבי
            </label>
          </li>
        </ul>
        <button
          type={"submit"}
          className={`button ${styles.form__btn} ${
            (isLoading || !touched || !form.desc) && styles.form__btn_disabled
          } `}
          disabled={isLoading || !touched || !form.desc}
          onClick={(e) => {
            e.preventDefault();
            addOrEditRRMHeaders({
              companyId: admin.companyId,
              RRMHeaders: {
                ...form,
                questionnaireId: Number(questionnaireId),
                companyId: admin.companyId,
              },
            })
              .then((res) => {
                if (res && "data" in res) {
                  setTouched(false);
                  setOpenModalSuccess(true);
                  console.log(res.data);
                  // setRRMToEditId(null);
                }
              })
              .catch((err) => {
                setOpenModalError(true);
                console.log(err);
              });
          }}
        >
          {isLoading ? "שליחה" : "שמור שינויים"}
        </button>
        {rrmId && form.isClini && (
          <RrmModel
            setRrmGroupToEdit={setRrmGroupToEdit}
            setShowGroupsRRMForm={setShowGroupsRRMForm}
          />
        )}
        {rrmId && form.isClini && (
          <article className={rrmHeadersStyles.rrmSection}>
            <button
              type={"button"}
              className={`${questionnaireStyles.addBtn} ${
                touched && "button_disabled"
              }`}
              onClick={() => {
                setRrmGroupToEdit(null);
                setShowGroupsRRMForm(true);
              }}
            >
              {"הוסף ציון"}
              <span className={questionnaireStyles.plus}>+</span>
            </button>
          </article>
        )}
      </form>
      {showGroupsRRMForm && (
        <AddOrEditGroupsRRMForm
          rrmGroupToEdit={rrmGroupToEdit}
          rrmHeaderId={rrmId}
          setShowGroupsRRMForm={setShowGroupsRRMForm}
          questionnaireId={questionnaireId}
        />
      )}
      {openModalSuccess && (
        <Modal
          onClose={() => {
            setOpenModalSuccess(false);
            // setShowAddOrEditRRMForm(false);
          }}
        >
          <GeneralSuccessPopup
            onClose={() => {
              setOpenModalSuccess(false);
              // setShowAddOrEditRRMForm(false);
            }}
          />
        </Modal>
      )}
      {openModalError && (
        <Modal onClose={() => setOpenModalError(false)}>
          <ErrorPopup onClose={() => setOpenModalError(false)} />
        </Modal>
      )}
    </section>
  );
}

export default AddOrEditRRMHeaders;
