import styles from "./rrmPage.module.css";
import questionnaireStyles from "../questionnaires/questionnaires.module.css";
import { useNavigate, useParams } from "react-router-dom";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { useAppSelector } from "../../services/hooks";
import {
  companyGroupsRRMHeadersSelector,
  questionsSelector,
} from "../../services/selectors/utils.selectors";
import {
  useAddOrEditQuestionsMutation,
  useGetCompanyGroupsRRMHeadersMutation,
  useGetQuestionsMutation,
} from "../../services/api/utils.api";
import React, { useEffect, useState } from "react";
import { adminSelector } from "../../services/selectors/user.selectors";
import useMediaQuery from "../../hooks/useMediaQuery";
import AddOrEditRRMHeaders from "../../components/forms/addOrEditRRMHeaders/addOrEditRRMHeaders";
import {
  ICompanyGroupsRRMHeaders,
  IQuestion,
} from "../../services/types/utils.types";
import DraggableQuestion from "../../components/draggableQuestion/draggableQuestion";
import DraggableRRMHeader from "../../components/draggableRRMHeader/draggableRRMHeader";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Modal from "../../components/modals/modal/Modal";
import GeneralSuccessPopup from "../../components/modals/generalSuccessPopup/generalSuccessPopup";
import ErrorPopup from "../../components/modals/errorPopup/errorPopup";

function RrmPage() {
  const { qid } = useParams();
  const navigate = useNavigate();

  const admin = useAppSelector(adminSelector);
  const questionsServer = useAppSelector(questionsSelector);
  const rrmHeaders = useAppSelector(companyGroupsRRMHeadersSelector);

  const mobile = useMediaQuery("(max-width: 800px)");

  const [headers, setHeaders] = useState<ICompanyGroupsRRMHeaders[]>([]);
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [showAddOrEditRRMForm, setShowAddOrEditRRMForm] = useState(false);
  const [RRMToEditId, setRRMToEditId] = useState<null | number>(null);
  const [touched, setTouched] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);

  const [getRRMHeaders] = useGetCompanyGroupsRRMHeadersMutation();
  const [getQuestions] = useGetQuestionsMutation();
  const [editQuestions, { isLoading }] = useAddOrEditQuestionsMutation();

  useEffect(() => {
    getRRMHeaders({ companyId: admin.companyId });
    qid && getQuestions({ questionnaireId: qid });
  }, [getRRMHeaders, admin.companyId, qid, getQuestions]);

  useEffect(() => {
    setHeaders([...rrmHeaders]);
    setQuestions([...questionsServer]);
  }, [getRRMHeaders, rrmHeaders, questionsServer]);

  const moveQuestion = (draggedId: number, targetId: number | null) => {
    console.log(3000, draggedId, targetId);
    if (targetId === null) return;
    setTouched(true);
    setQuestions((prevQuestions) => {
      const draggedQuestion = prevQuestions.find((q) => q.id === draggedId);
      if (!draggedQuestion) return prevQuestions;

      if (targetId === null) {
        return prevQuestions;
      }

      // Remove the dragged question from the list
      const updatedQuestions = prevQuestions
        .filter((q) => q.id !== draggedId)
        .map((q, index) => ({
          ...q,
          seq: index + 1,
        }));

      // Find the target index and insert the dragged question
      const targetIndex = updatedQuestions.findIndex((q) => q.id === targetId);
      updatedQuestions.splice(targetIndex, 0, {
        ...draggedQuestion,
        seq: targetIndex + 1,
      });

      return updatedQuestions.map((q, index) => ({ ...q, seq: index + 1 }));
    });
  };

  const updateQuestionGroup = (questionId: number, headerId: number) => {
    setTouched(true);

    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId ? { ...q, companyGroupsRRMHeadersId: headerId } : q
      )
    );
  };

  if (qid && showAddOrEditRRMForm)
    return (
      <section className={styles.section}>
        <BackArrowButton
          text={"למסך הקודם"}
          position={"center"}
          onClick={() => {
            setShowAddOrEditRRMForm(false);
            setRRMToEditId(null);
          }}
        />
        <h1 className={styles.section__title}>
          {RRMToEditId ? "עריכת קבוצת RRM" : "הוספת קבוצת RRM"}
        </h1>
        <AddOrEditRRMHeaders
          questionnaireId={qid}
          setShowAddOrEditRRMForm={setShowAddOrEditRRMForm}
          setRRMToEditId={setRRMToEditId}
          rrmId={RRMToEditId}
        />
      </section>
    );
  return (
    <section className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        position={"center"}
        onClick={() => navigate(-1)}
      />
      <div className={styles.section__header}>
        <button
          type={"button"}
          className={questionnaireStyles.addBtn}
          onClick={() =>
            navigate(`/protocols/questionnaires/edit/${qid}/questions/edit`)
          }
        >
          {mobile ? "?" : "הוסף שאלה"}
          <span className={questionnaireStyles.plus}>+</span>
        </button>
        <h1 className={styles.section__title}>עריכת קבוצות RRM</h1>
        <button
          type={"button"}
          className={questionnaireStyles.addBtn}
          onClick={() => setShowAddOrEditRRMForm(true)}
        >
          {mobile ? "RRM" : "הוסף RRM"}
          <span className={questionnaireStyles.plus}>+</span>
        </button>
      </div>
      <DndProvider backend={HTML5Backend}>
        <div className={styles.draggableContainer}>
          <ul className={styles.list}>
            {questions
              .sort((a, b) => a.seq - b.seq)
              .map((question) => (
                <DraggableQuestion
                  key={question.id}
                  question={question}
                  moveQuestion={moveQuestion}
                />
              ))}
          </ul>
          <ul className={styles.list}>
            {headers.map((header) => (
              <DraggableRRMHeader
                key={header.id}
                header={header}
                onDropQuestion={updateQuestionGroup}
                setRRMToEditId={setRRMToEditId}
                setShowAddOrEditRRMForm={setShowAddOrEditRRMForm}
                questions={questions.filter(
                  (q) => q.companyGroupsRRMHeadersId === header.id
                )}
              />
            ))}
          </ul>
        </div>
      </DndProvider>
      <button
        className={`button ${(!touched || isLoading) && "button_disabled"}`}
        type={"button"}
        disabled={!touched || isLoading}
        onClick={(e) => {
          setTouched(false);
          console.log(questions, headers);
          e.preventDefault();
          qid
            ? editQuestions({
                questionnaireId: qid,
                questions: questions,
              })
                .then((res) => {
                  if (res && "data" in res) {
                    setOpenModalSuccess(true);
                  }
                })
                .catch((err) => {
                  setOpenModalError(true);
                  console.log(err);
                })
            : setOpenModalError(true);
        }}
      >
        שמור שינויים
      </button>
      {openModalSuccess && (
        <Modal onClose={() => setOpenModalSuccess(false)}>
          <GeneralSuccessPopup
            onClose={() => {
              setOpenModalSuccess(false);
            }}
          />
        </Modal>
      )}
      {openModalError && (
        <Modal onClose={() => setOpenModalError(false)}>
          <ErrorPopup onClose={() => setOpenModalError(false)} />
        </Modal>
      )}
    </section>
  );
}

export default RrmPage;
