import { TGroup, TQuestionOption } from "../../services/types/utils.types";
import React from "react";
import { useDrag, useDrop } from "react-dnd";
import styles from "../../pages/addOrEditOptions/addOrEditOptions.module.css";
import { ITEM_TYPE } from "../../utils/constants";

interface DraggableGroupProps {
  group: TGroup;
  moveGroup: (draggedGroup: TGroup, targetGroup: TGroup) => void;
  updateGroup: (
    draggedOption: TQuestionOption,
    newGroupId: number | null
  ) => void;

  children: React.ReactNode;
}
function DraggableGroup({
  group,
  moveGroup,
  children,
  updateGroup,
}: DraggableGroupProps) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ITEM_TYPE.GROUP,
    item: { group },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop({
    accept: [ITEM_TYPE.GROUP, ITEM_TYPE.OPTION], // Accept both groups and options
    hover: (draggedItem: { group?: TGroup; option?: TQuestionOption }) => {
      if (draggedItem.group && draggedItem.group.id !== group.id) {
        moveGroup(draggedItem.group, group);
      } else if (draggedItem.option) {
        // Move option to the current group
        updateGroup(draggedItem.option, group.id);
      }
    },
  });

  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <p className={styles.group}>
        {group.id}: {group.name}, משקולת {group.factorGroup}
      </p>
      {children}
    </div>
  );
}

export default DraggableGroup;
